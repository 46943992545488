const regioes = [
  {
    title: "Amapá",
    slug: "amapa",
    titleSEO: "Material Educacional | Amapá | Material Didático",
  },
  {
    title: "Ceará",
    slug: "ceara",
    titleSEO: "Material Estruturado | Ceará | Material Didático",
  },
  {
    title: "Mato Grosso do Sul",
    slug: "mato-grosso-do-sul",
    titleSEO:
      "Xperience Nova Escola | Mato Grosso do Sul | Livro de inglês | Material Didático",
  },
  {
    title: "Paraná",
    slug: "parana",
    titleSEO:
      "Xperience Nova Escola | Paraná | Livro de inglês | Material Didático",
  },
  {
    title: "Pernambuco",
    slug: "pernambuco",
    titleSEO:
      "Xperience Nova Escola | Pernambuco | Livro de inglês | Material Didático",
  },
  {
    title: "Piauí",
    slug: "piaui",
    titleSEO:
      "Material Didático Complementar | Piauí | Material Didático",
  },
  {
    title: "São Paulo",
    slug: "sao-paulo",
    titleSEO: "Currículo em Ação | São Paulo | Material Didático",
  },
];

const regioesComNacional = [
  {
    title: "Amapá",
    slug: "amapa",
    url: "catalogo/amapa",
    description: "Referencial Curricular Amapaense",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Amapa_2+2.png",
  },
  {
    title: "Ceará",
    slug: "ceara",
    url: "catalogo/ceara",
    description: "Referencial Curricular Cearense",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Ceara_2+2.png",
  },
  {
    title: "Mato Grosso do Sul",
    slug: "mato-grosso-do-sul",
    url: "catalogo/mato-grosso-do-sul",
    description: "Referencial Curricular Sul-Mato Grossense",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Mato-Grosso-do-Sul_2+1.png",
  },
  {
    title: "Paraná",
    slug: "parana",
    url: "catalogo/parana",
    description: "Referencial Curricular Paranaense",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Parana_2+1.png",
  },
  {
    title: "Pernambuco",
    slug: "pernambuco",
    url: "catalogo/pernambuco",
    description: "Referencial Curricular Pernambucano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Pernambuco_2+2.png",
  },
  {
    title: "Piauí",
    slug: "piaui",
    url: "catalogo/piaui",
    description: "Referencial Curricular Piauíense",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Piaui_2+2.png",
  },
  {
    title: "São Paulo",
    slug: "sao-paulo",
    url: "catalogo/sao-paulo",
    description: "Referencial Curricular Paulista",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Sao-Paulo_2+2.png",
  },
  {
    title: "Não regionalizado",
    slug: "nacional",
    url: "catalogo/nacional",
    description: "Todas as habilidades da BNCC",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/Brasil_2+2.png",
  },
];

const anos = [
  {
    title: "Bebês",
    titleSEO: "Material Didático Bebês | Livros gratuitos para professores e alunos",
    slug: "bebes",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_1.png",
  },
  {
    title: "Crianças bem pequenas",
    titleSEO: "Material Didático Crianças Bem Pequenas | Livros gratuitos para professores e alunos",
    slug: "criancas-bem-pequenas",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_1.png",
  },
  {
    title: "Crianças pequenas",
    titleSEO: "Material Didático Crianças Pequenas | Livros gratuitos para professores e alunos",
    slug: "criancas-pequenas",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_1.png",
  },
  {
    title: "1º Ano",
    titleSEO: "Material Didático 1º ano | Livros gratuitos para professores e alunos",
    slug: "1-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_2.png",
  },
  {
    title: "2º Ano",
    titleSEO: "Material Didático 2º ano | Livros gratuitos para professores e alunos",
    slug: "2-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_2.png",
  },
  {
    title: "3º Ano",
    titleSEO: "Material Didático 3º ano | Livros gratuitos para professores e alunos",
    slug: "3-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_2.png",
  },
  {
    title: "4º Ano",
    titleSEO: "Material Didático 4º ano | Livros gratuitos para professores e alunos",
    slug: "4-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_2.png",
  },
  {
    title: "5º Ano",
    titleSEO: "Material Didático 5º ano | Livros gratuitos para professores e alunos",
    slug: "5-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_2.png",
  },
  {
    title: "6º Ano",
    titleSEO: "Material Didático 6º ano | Livros gratuitos para professores e alunos",
    slug: "6-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_3.png",
  },
  {
    title: "7º Ano",
    titleSEO: "Material Didático 7º ano | Livros gratuitos para professores e alunos",
    slug: "7-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_3.png",
  },
  {
    title: "8º Ano",
    titleSEO: "Material Didático 8º ano | Livros gratuitos para professores e alunos",
    slug: "8-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_3.png",
  },
  {
    title: "9º Ano",
    titleSEO: "Material Didático 9º ano | Livros gratuitos para professores e alunos",
    slug: "9-ano",
    image:
      "https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE__Nova_Marca__Etapas_3.png",
  },
];

export { regioes, regioesComNacional, anos };
