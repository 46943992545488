import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web";
import { history } from "instantsearch.js/es/lib/routers";
import { isMobile } from "react-device-detect";

import Layout from "../components/Layout";
import Seo from "../components/general/Seo";
import BreadCrumb from "../components/general/Breadcrumb";

import CustomCurrentRefinements from "../components/busca/CustomCurrentRefinements";
import {
  AnoCustomRefinementList,
  ComponenteCustomRefinementList,
  NaoRegionalizadoCustomRefinementList,
  RegiaoCustomRefinementList,
} from "../components/busca/refinementList";
import Results from "../components/busca/Results";
import CustomPagination from "../components/busca/CustomPagination";
import { anos, regioes } from "../components/general/_/values";

const INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME as string;
const HITS_PER_PAGE = 12;
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || "",
  process.env.GATSBY_ALGOLIA_API_KEY || ""
);

const routingSetting = (setValues: Function) => {
  return {
    router: history({
      parseURL({ location }): any {
        const pathnameMatches = location.pathname.match(/\/(.*?)\/?$/);
        const slug = pathnameMatches?.[1] || "";
        const parameters = slug.split("/").map(decodeURIComponent);
        const regiao: string[] = [];
        const regionalizado: boolean[] = [];
        const ano: string[] = [];

        parameters.forEach((element) => {
          if (element === "nacional") {
            regionalizado.push(false);

            return;
          }

          const regiaoIndex = regioes.findIndex((el) => el.slug === element);

          if (regiaoIndex !== -1) {
            regiao.push(regioes[regiaoIndex].title);

            return;
          }

          const anoIndex = anos.findIndex((el) => el.slug === element);

          if (anoIndex !== -1) {
            ano.push(anos[anoIndex].title);

            return;
          }
        });

        return {
          regiao: regiao.join(" "),
          regionalizado: regionalizado.join(" "),
          ano: ano.join(" "),
        };
      },
    }),
    stateMapping: {
      stateToRoute(uiState: any) {
        const indexUiState = uiState[INDEX_NAME];
        const regiao = indexUiState.refinementList?.regiao;
        const ano = indexUiState.refinementList?.ano;

        setValues({ regiao, ano });

        return {};
      },
      routeToState({ regionalizado, regiao, ano, componente }: any) {
        return {
          [INDEX_NAME]: {
            refinementList: {
              regionalizado: regionalizado ? [regionalizado] : undefined,
              regiao: regiao ? [regiao] : undefined,
              ano: ano ? [ano] : undefined,
              livros: { componentes: componente },
            },
          },
        };
      },
    },
  };
};

type CatalogoPageProps = {
  pageContext: {
    id: string;
    slug: string;
    titleSEO?: string;
  };
};

const CatalogoPage: React.FC<CatalogoPageProps> = ({ pageContext }) => {
  const titleSEO = pageContext.titleSEO
    ? pageContext.titleSEO
    : "Material didático | Livros gratuitos para professores e alunos";
  const [values, setValues]: any = useState();

  return (
    <Layout heroClass="no-hero" headerClass="padding-header">
      <Seo
        title={titleSEO}
        description="Encontre os materiais do professor e do aluno"
      />

      <main className="container busca eva-margin-top-4">
        <BreadCrumb
          items={[
            { text: "Material Educacional", url: "/" },
            { text: "Catálogo de Materiais" },
          ]}
        />
        <p className="eva-title-4 eva-margin-bottom-3">
          Encontre os materiais do professor e do aluno
        </p>
        <p className="eva-title-5 eva-margin-bottom-2">Busca e filtros</p>

        <InstantSearch
          searchClient={searchClient}
          indexName={INDEX_NAME}
          routing={routingSetting(setValues)}
        >
          <Configure hitsPerPage={HITS_PER_PAGE} />

          <div className="row">
            <aside className="col-sm-12 col-md-3">
              <div className="container-search-theme eva-margin-bottom-4">
                <div id="inputBusca">
                  <i className="fas fa-search eva-input-icon" />
                  <SearchBox placeholder="Busque materiais" />
                </div>

                {isMobile && <CustomCurrentRefinements />}

                <NaoRegionalizadoCustomRefinementList attribute="regionalizado" />

                <RegiaoCustomRefinementList
                  attribute="regiao"
                  selectedValues={values?.regiao}
                  sortBy={["name:asc"]}
                />

                <AnoCustomRefinementList
                  attribute="ano"
                  showMoreLimit={3}
                  selectedValues={values?.ano}
                  sortBy={["name:asc"]}
                />

                <ComponenteCustomRefinementList
                  attribute="livros.componentes"
                  showMoreLimit={3}
                />
              </div>
            </aside>

            <section
              id="resultadosBusca"
              className="col-sm-12 col-md-9 no-padding"
            >
              <div className="row">
                <div className="col">
                  <Results />
                </div>
              </div>

              <div className="row d-flex jc-center eva-margin-top-4">
                <CustomPagination />
              </div>
            </section>
          </div>
        </InstantSearch>
      </main>
    </Layout>
  );
};

export default CatalogoPage;
